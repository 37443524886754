import React from "react"
import { graphql, Link, useStaticQuery } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"
import absentblocks from "../images/absent.jpeg"
import Img from "gatsby-image"

const SecondPage = () => {
  const data = useStaticQuery(graphql`
    query {
      whereishe: file(relativePath: { eq: "whereishe.jpeg" }) {
        childImageSharp {
          fluid(maxWidth: 400) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)

  return (
    <Layout>
      <SEO
        title="verzuimbegeleiding"
        description="De Zipperr® Methode maakt gebruik van ReAttach therapie voor verzuimbegeleiding en verzuimbeperking van werknemers."
      />
      <div
        style={{
          width: "100vw",
          height: "250px",
          overflow: "hidden",
          backgroundImage: `url(${absentblocks})`,
          backgroundSize: "cover",
          backgroundPosition: "center",
        }}
      >
        {/*<img style={{width: '100vw'}} src={office} alt=""/>*/}
      </div>
      <div className="highlightedText containerPadModified">
        {/*<div className="tiny-header centerText">Breinkracht organisatieadvies op basis van de Zipperr® Methode</div>*/}
        <div className="heading">
          <div>
            <h1 className={"subTitleContent centerText"}>
              Efficiënte verzuimbegeleiding en verzuimbeperking
            </h1>
          </div>
        </div>
        <div className="subhead">
          <p className=" afterlife">Mensvriendelijke therapie</p>
        </div>
        <p>
          De Zipperr® Methode maakt gebruik van een kortdurende mensvriendelijke
          therapie, de ReAttach methode genoemd, welke de Clinical
          Neuropsychiatry AWARD 2015 gewonnen heeft en ingezet wordt voor
          verzuimbegeleiding met betrekking tot werknemers met o.a. burn-out
          klachten, depressies, slaapproblemen, concentratiestoornissen en
          chronische posttraumatische stress.
        </p>
        <Link to={"/organisatieeffectiviteit"}>
          Lees meer over de ReAttach methode
        </Link>
      </div>

      <div className={"containerPad flexy"}>
        <div className={"half block contntr"}>
          <h2>Zieke werknemer</h2>
          <p>
            Een zieke werknemer kost veel geld. Het belangrijkste doel van
            bovengenoemde methode is om te voorkomen dat mensen uitvallen.
            Voorkomen is immers beter dan genezen. Bij mentale problematiek is
            het belangrijk om op onbewust niveau te begeleiden om verzuim te
            beperken.
          </p>
          <p>
            Zipperr® helpt bij het terugdringen van ziekteverzuim, hiervoor
            gaat zipper trajecten aan met uw organisatie en halen wij het
            optimale uit de personen.
          </p>
          <p className={"btn-container"}>
            <Link
              to="/traject"
              className="btn primary-lg round expand-on-hover"
            >
              Meer informatie
            </Link>
          </p>
        </div>
        <div className={"half block imger"}>
          <div className={"imageStackedBox"}>
            <Img
              fluid={data.whereishe.childImageSharp.fluid}
              objectFit="cover"
              // objectPosition="50% 50%"
              alt="Zipper Case"
              style={{ maxHeight: "45%" }}
            />
          </div>
        </div>
      </div>
    </Layout>
  )
}

export default SecondPage
